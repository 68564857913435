// Variables
$gs-primary: #122649; // Blue
$gs-secondary: #f0b41c; // Yellow
$default-background: #f5f5f5; // Grey
$darken-default-background: darken($default-background, 40%); // Grey
// https://mui.com/material-ui/customization/palette/
$mui-success-main: #2e7d32; // Green
$mui-success-alert-bg: #edf7ed; // pale success green
$mui-error-light: #ef5350;
$app-box-bg: white;
$app-box-padding: 24px;
$app-box-padding-mobile: 16px;

$mui_error_light: #e57373;
$mui_error_main: #f44336;
$mui_error_dark: #d32f2f;
$mui_warning_light: #ffb74d;
$mui_warning_main: #ffa726;
$mui_warning_dark: #f57c00;
$mui_info_light: #4fc3f7;
$mui_info_main: #29b6f6;
$mui_info_dark: #0288d1;
$mui_success_light: #81c784;
$mui_success_main: #66bb6a;
$mui_success_dark: #388e3c;

$break-point-tablet-portrait: 600px;
$break-point-tablet-landscape: 900px;

$button-like-border-radius: 4px;
$button-like-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // from amplify package we removed:
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  min-height: 100vh;
}

// from amplify package that we removed
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-amplify-authenticator] {
  display: none;
  [data-amplify-container] {
    [data-amplify-header] {
      text-align: center;
      margin-top: 100px;
    }
    [data-amplify-footer] {
      text-align: center;
      font-size: 80%;
    }
  }
}

.App-content {
  background-color: $default-background;
  min-height: 100vh;
  padding: 15px;
}

.App-box,
.App-panel-horizontal {
  background-color: $app-box-bg;
  padding: $app-box-padding;
  height: calc(100vh - #{$app-box-padding} * 4);
  overflow-x: auto;
  overflow-y: auto;
  white-space: normal;
  transition: border 300ms ease-out;
  position: relative;
  &.active {
    border: 1px $gs-primary solid;
    padding: $app-box-padding - 1;
  }

  &.App-box-horizontal {
    height: inherit;
  }

  h1 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  h2 {
    margin-top: 0px;
    .highlight {
      color: $gs-primary;
    }
  }
  * ~ h2 {
    // h2 not at the top of .app-box
    margin-top: 16px;
  }
  h2 ~ .sub-title,
  div.h3 ~ .sub-title {
    color: $darken-default-background;
    margin-top: -20px;
    margin-block-end: 1em;
    span {
      margin-right: 20px;
      font-size: 90%;
    }
    border-bottom: 1px solid $darken-default-background;
  }

  .bottom-button {
    position: absolute;
    bottom: $app-box-padding;
  }

  .drop-zone {
    background-color: $default-background;
    border-radius: 3px;
    border: 2px $gs-primary solid;
    text-align: center;
    padding: 30px;
    &.active {
      border: 2px $gs-primary dotted;
      background-color: darken($default-background, 10%);
    }
    p,
    small {
      // background-color: #f0b41c;
      white-space: normal;
      overflow-wrap: break-word;
    }
  }
  .drop-zone.success {
    background-color: $mui-success-alert-bg;
    border-color: $mui-success-main;
    &.active {
      border-color: $mui-success-main;
      background-color: darken($mui-success-alert-bg, 10%);
    }
    .MuiSvgIcon-colorPrimary {
      color: $mui-success-main;
    }
  }
  .drop-zone-button {
    background-color: $default-background;
    border-radius: 3px;
    border: 2px $gs-primary solid;
    margin-top: 10px;
    line-height: 1.2em;
    width: 100%;
    &:hover {
      border: 2px $gs-primary solid;
    }
  }
  .custom-label {
    font-weight: bold;
    min-width: 100px;
    margin-right: 16px;
  }
  .custom-link {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: $gs-primary;
    &:hover {
      color: darken($gs-primary, 20%);
    }
    &:visited,
    &:active {
      color: $gs-primary;
    }
  }
}

.App-box.horizontal {
  border-bottom: 1px solid $gs-primary;
  padding: 0px;
}

.App-panel-horizontal {
  border-bottom: 1px solid $gs-primary;
  min-height: unset;
}

.jobs-table {
  th {
    background-color: #{$gs-primary}cc;
    color: white;
  }
}

.image-data-table {
  // Repeated classname for specificity increase
  // Source: https: //www.w3.org/TR/selectors-3/#specificity
  .MuiDataGrid-cell.MuiDataGrid-cell {
    padding: 12px 10px;
  }

  &__cell {
    &--orange {
      background-color: lighten($mui_warning_main, 10%);
    }
  }
}

.image-days-form {
  max-width: 4rem;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.icon-success {
  color: $mui-success-main;
}

.icon-error {
  color: $mui-error-light;
}

.add-multiple-list {
  background-color: $default-background;
  height: 300px;
  overflow-y: auto;
  list-style-type: none;
}

.Dash-box {
  padding: 0px !important;
  border-radius: 3px;
  h3 {
    margin-top: 0;
    padding: 5px;
    // border-bottom: 1px solid $gs-primary;
    // background-color: lighten(#f0b41c, 20%);
    background-color: $default-background;
  }
}

.projects-card {
  border: 1px solid $gs-primary;
  .details {
    & > div {
      padding: 10px;
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      width: 50px;
      text-align: center;
      & > * {
        vertical-align: middle;
      }
    }
    .content {
      width: calc(100% - 50px);
      div {
        display: block;
      }
      .type {
        font-size: 80%;
        color: grey;
      }
      .title {
        font-weight: bold;
        font-size: 110%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .description {
        // line-height: 1em;
        // height: 2.1em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .go-to-action {
    display: block;
    background-color: $gs-primary;
    color: $default-background;
    text-align: center;
    text-decoration: none;
    padding: 10px;
  }
}

// To handle when title is not an input field
.admin-editable.title.h3, .editable.title.h3 {
  font-size: large;
  font-weight: bold;
  align-items: center;
  span {
    font-family: Source Sans Pro, sans-serif;
    padding: 4px 10px 5px;
    box-sizing: content-box;
    width: 100%;
  }
}

// input elements disabled for non-admin users
div.MuiFormControl-root.admin-editable,
div.MuiFormControl-root.editable {
  &.title {
    div.MuiInputBase-root {
      // make text look like h3
      font-weight: bold;
    }
    &.h3 {
      div.MuiInputBase-root {
        // to make text look like h2
        font-size: large;
      }
    }
  }
  div.MuiInputBase-root {
    // override text color
    color: black;
    &.Mui-disabled:before {
      // when disabled, remove border
      border-style: none;
    }
    input,
    textarea {
      // override text color
      color: black;
      -webkit-text-fill-color: black;
    }
  }
}

.MuiDataGrid-row--editing .MuiDataGrid-cell--editable {
  background-color: $default-background !important;
}

.MuiDataGrid-cell.warning {
  background-color: $mui-warning-light;
}

.MuiDataGrid-cell.invalid {
  background-color: $mui-error-light;
}

.MuiDataGrid-root {
  min-width: 325px;
}

.uppercase {
  text-transform: uppercase;
}

nav.breadcrumb {
  margin: 0px;
  padding: 0px;
  line-height: 27px;
  li {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    // margin-bottom: 5px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    svg.MuiChip-icon {
      margin-right: 6px;
    }
    span:empty {
      cursor: pointer;
      display: none;
    }
  }
  + .MuiGrid-root {
    margin-top: 0;
    padding-top: 0;
    .App-box {
      height: calc(100vh - #{$app-box-padding} * 4 - 40px); // Adding breadcrumb
    }
  }
}

.project-switch {
  div.MuiInput-underline::before {
    border-bottom-color: white;
  }
  div::after {
    border-bottom-color: white;
  }
  label.Mui-focused {
    color: white;
  }
}

.sidebar {
  transition: width 0.2s ease;
  width: 0;
}

.sidebar.open {
  width: 270px;
}

div.breadcrumb-dropdown {
  background-color: $default-background;
  border: 1px solid darken($default-background, 20%);
  border-radius: 15px;
  max-width: 200px;
  min-width: 100px;
  margin-top: 12px;
  padding: 8px;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 0.9em;
    li {
      a {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-decoration: none;
        cursor: pointer;
        display: block;
        padding: 0 2px;
        // margin: 0 -8px;
        &:visited {
          color: inherit;
        }
        &:hover {
          background-color: darken($default-background, 6%);
          border-radius: 3px;
        }
      }
    }
  }
}

.image-setting-advance {
  h3 {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
    align-items: center;
  }
}

.mask-overlap-checkbox {
  margin: 0 !important;

  span {
    margin-right: 0 !important;
  }
}

.table-view-checkbox {
  span {
    margin-right: 0 !important;
  }
}

.add-image {
  a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    min-height: 150px;
    width: 100%;
    height: 100%;
    text-align: center;

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    svg {
      visibility: visible;
    }

    :hover {
      background-color: darken($default-background, 10%);
    }
  }
}

.load-images {
  span {
    padding-top: 70px;
    text-decoration: none;
    color: inherit;
    display: inline-block;
    width: 100%;
    text-align: center;
    height: 100%;
    visibility: inherit !important;
    cursor: pointer;
    & svg {
      visibility: inherit !important;
    }
  }
}

.files-to-upload {
  li {
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
    // background-color: red;
    padding-left: 0;
  }
}

.job-data .jobs-title:last-child {
  margin-right: 100px;
}

.jobs-card {
  position: relative;
  padding: 10px;
  border: 1px solid darken($default-background, 10%);

  div.jobs-title {
    position: relative;
    font-size: 90%;
    margin: 0;

    span {
      display: inline-block;
      vertical-align: middle;
    }
    .job-creator {
      font-weight: lighter;
      font-size: 90%;
    }
  }
  .dateTime {
    margin-bottom: 5px;
    color: darken($default-background, 40%);
    small {
      display: inline-block;
      vertical-align: middle;
      padding-right: 8px;
    }
  }
  .jobAction {
    position: absolute;
    right: 10px;
    bottom: 10px;
    button,
    a {
      margin-top: 5px;
    }
  }
}

pre.api-response,
div.api-response {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 300px;
  padding: 5px;
}

form small.error {
  color: $mui-error-light;
}

.MuiFormControl-root.image-setting-inputs {
  width: 100%;
  // margin-top: 10px;
  background-color: $default-background;
  padding: 3px;
  border-radius: 3px;
  align-content: flex-start;
  label {
    position: inherit;
    & + * {
      margin-top: 0;
    }
  }
  input {
    width: calc(100% - 45px);
    font-family: monospace;
  }
}

.color-label {
  flex-direction: row !important;
  align-items: center;
  width: 100% !important;
  border-radius: 0 !important;

  // Color Picker
  div:first-child {
    border-radius: 0 !important;

    button {
      width: 30px;
      height: 30px;
      margin: 0 0.8rem 0 0;
      border-radius: 4px;
      border: 1px solid $darken-default-background;
      box-shadow: none;
    }
  }

  p {
    font-size: 0.9rem;
  }
}

.chart-image {
  transform: rotate(90deg) translateY(-100%) scale(1);
  transform-origin: left top;
  cursor: pointer;
  &.highlighted-image {
    border: 2px solid red;
  }
}

.dialog-alert-details {
  pre {
    background-color: $default-background;
    padding: 1rem;
  }
  &.info div.MuiDialog-paper {
    pre {
      color: darken($mui_info_dark, 25%);
      background-color: lighten($mui_info_light, 25%);
    }
  }
  &.error div.MuiDialog-paper {
    pre {
      color: darken($mui_error_dark, 25%);
      background-color: lighten($mui_error_light, 25%);
    }
  }
  &.warning div.MuiDialog-paper {
    pre {
      color: darken($mui_warning_dark, 25%);
      background-color: lighten($mui_warning_light, 25%);
    }
  }
  &.success div.MuiDialog-paper {
    pre {
      color: darken($mui_success_dark, 25%);
      background-color: lighten($mui_success_light, 25%);
    }
  }
}

.visual-image-container {
  margin: 0;
  width: fit-content;
  display: inline-block;
  height: 100%;
}

.visual-setting {
  border: 2px solid #cbcbcb;
  padding: 1rem;
  height: fit-content;
  overflow: hidden;
  margin-bottom: 1rem;
}

.visual-side-panel {
  position: sticky;
  top: 1rem;
  margin-left: 2rem;
  min-width: 200px;
}

.visual-scale-text {
  line-height: 20px;
  writing-mode: vertical-lr;
  margin: 0;
  position: sticky;
  top: 50%;
  height: fit-content;
}

.visual-drillhole-option {
  display: flex;
  gap: 1rem;
  margin-top: -16px;
}

.visual-charts {
  height: 100%;
  border: 1px solid darken($default-background, 10%);
  overflow: auto;
  width: calc(100%) !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  position: relative;
  padding-right: 20px;

  /* Strip-Logs*/
  .icon-buttons {
    top: 45px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    cursor: grab;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $default-background;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: darken($default-background, 10%);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $darken-default-background;
  }
  /* Handle meet corner */
  &::-webkit-scrollbar-corner {
    background: $default-background;
  }

  > .visual-chart-wrap {
    margin-left: 80px;
  }

  > .visual-chart-wrap ~ .visual-chart-wrap {
    margin-left: 0;
  }
}

.regex-builder {
  &.display {
    display: inline-block;
    margin: 1px;
    min-width: 0px;
    word-wrap: normal;
    word-break: keep-all;
    &.property,
    &.option,
    &.button {
      padding: 3px 6px 3px 6px;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
      background-color: $darken-default-background;
      color: white;
      vertical-align: bottom;
      font-size: small;
      border: none;
      &.match {
        background-color: $gs-primary;
      }
    }
    &.property {
      padding-left: 6px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      cursor: default;
    }
    &.button {
      padding-left: 3px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      cursor: pointer;
      &:hover {
        background-color: darken($darken-default-background, 20%);
      }
      &.match:hover {
        background-color: darken($gs-primary, 20%);
      }
    }
    &.button,
    &.option {
      padding-block-end: 3px;
      padding-block-start: 6px;
      padding-inline-start: 3px;
      padding-inline-end: 6px;
    }
    &.option {
      padding-block-start: 3px;
      padding-block-end: 3px;
      padding-inline-start: 9px;
      padding-inline-end: 9px;
      margin-left: 2px;
      margin-right: 2px;
      line-height: unset;
      cursor: pointer;
      &:hover {
        background-color: darken($darken-default-background, 20%);
      }
      &.match:hover {
        background-color: darken($gs-primary, 20%);
      }
      &.disabled {
        background-color: #ddd;
        color: darken(#ddd, 50%);
        &:hover {
          background-color: #ddd;
          color: darken(#ddd, 50%);
          cursor: default;
        }
      }
    }
    &.text {
      padding-inline: 6px;
      padding-block: 3px;
      border: none;
      font-family: monospace;
      margin-inline-start: 3px;
      margin-inline-end: 3px;
      background-color: #ddd;
      text-align: center;
      font-size: large;
      &:focus-visible {
        outline: none;
      }
    }
  }
}

div.registered-image-options-list-existing {
  padding: 5px;
  background-color: $default-background;
  overflow-y: auto;
  text-align: left;
  border: 2px solid $darken-default-background;
  border-radius: 2px;
  max-height: 200px;
}

@media (max-width: $break-point-tablet-portrait) {
  // 600 px
  .visual-charts::-webkit-scrollbar {
    width: 0;
  }
}

.visual-chart-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
}

.global-tick-wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: fit-content;
  width: 100%;
}

.visual-chart-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 40px;
  margin: 0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  text-align: center;
  border-bottom: 1px solid darken($default-background, 10%);
  height: max-content;
  overflow-x: hidden;
  height: 45px;
  font-size: 90%;
}

.visual-chart-name-log {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  margin: 0;
  text-align: center;
  height: max-content;
  overflow-x: hidden;
  font-size: 90%;
}

h5.visual-chart-name-log {
  font-weight: normal;
  font-size: 60%;
}

.visual-chart-name-log-wrap {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 5;
  overflow-x: hidden;
  height: 45px;
  border-bottom: 1px solid darken($default-background, 10%);
}

.chart-tick {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.chart-tick-line {
  width: 100%;
  border-bottom: 3px solid darken($default-background, 10%);
}

.chart-bar {
  stroke: darken($default-background, 50%);
  stroke-width: 1.5;
  fill: #b4c6fb;
}

.chart-tick-text {
  position: sticky;
  left: 0;
  top: 0;
  color: black;
  font-size: 12px;
  padding: 0 5px;
  background: #fff;
  margin: 0;
  z-index: 1;
}

.visual-chart-depth {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 40px;
  margin: 0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  text-align: center;
  border-bottom: 1px solid darken($default-background, 10%);
  height: max-content;
  overflow-x: hidden;
  height: 45px;
  width: 100%;
}

.visual-option {
  display: flex;
  justify-content: center;
}

.visual-select-form {
  width: 100%;
}

.visual-container {
  padding-top: 1rem;
  justify-content: space-around;
  height: 100%;
  overflow-x: scroll;

  @media (max-width: $break-point-tablet-portrait) {
    justify-content: flex-start;
  }
}

.chart-container {
  display: flex;
  justify-content: center;
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-top: 10px;
}

.additional-controls {
  padding: 10px 0;
  margin-bottom: 10px;
  border-top: 1px solid darken($default-background, 10%);
}
// Logs Explorer
div.logs-explorer-logs-list {
  width: 100%;
}

h1.log-explorer-description.title {
  font-size: medium;
  font-weight: 700;
}

div.MuiFormControl-root.logs-list {
  width: 100%;
  min-width: 120px;
  max-width: 550px;
  margin: 8px 0;
}

h2.MuiDialogTitle-root.log-heading,
div.MuiDialogContent-root.log-content {
  padding-left: 25px;
  padding-right: 25px;
}

.image-upload-summary-group {
  text-align: left;
  display: inline-block;
  height: 40px;
  align-content: center;
}

.image-upload-summary-icon {
  margin-left: 10px;
  &.validation {
    color: $mui-error-light;
  }
  &.error {
    color: $mui-error-main;
  }
  &.gaps {
    color: $mui-warning-light;
  }
  &.zero {
    color: $darken-default-background;
  }
  &.success {
    color: $mui-success-main;
  }
}

.image-upload-summary-value {
  padding-left: 2px;
  padding-right: 2px;
  vertical-align: top;
  &.zero {
    color: $darken-default-background;
  }
}

.copyToClipboardFeedback {
  padding: 3px 5px;
  background-color: $default-background;
  border-color: $gs-primary;
  border-radius: $button-like-border-radius;
  color: $gs-primary;
  box-shadow: $button-like-box-shadow;
}

p.copyToClipboardButton {
  font-size: small;
  display: inline-block;
}

div.show-more-show-less {
  position: relative;
  margin-top: -16px;
  div.collapsible-text-content {
    overflow: hidden;
    padding-bottom: 10px;
  }
  div.collapisble-text-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    padding-bottom: 10px;
    pointer-events: none;
    background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.99),
      10%,
      rgba(255, 255, 255, 0.01) 25%
    );
    display: initial;
    width: 100%;
    &.open {
      display: none;
    }
  }
  button.text-like-button {
    background: none;
    border: none;
    box-shadow: none;
    color: $gs-primary;
    cursor: pointer;
    &.less {
      display: none;
      &.open {
        display: block;
      }
    }
    &.more {
      display: block;
      &.open {
        display: none;
      }
    }
  }
}

// extending viewerjs to show previous image icon
.viewer-previous-image {
  color: #fff;
  // Material Icons font imported in index.html
  font-family: Material Icons, serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}
.viewer-previous-image::before {
  // double arrow up
  content: '\eacf';
}
// extending viewerjs to show next image icon
.viewer-next-image {
  color: #fff;
  // Material Icons font imported in index.html
  font-family: Material Icons, serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}
.viewer-next-image::before {
  // double arrow down
  content: '\ead0';
}
// extending viewerjs to show rectify mask icon
.viewer-rectify-mask {
  color: #fff;
  // Material Icons font imported in index.html
  font-family: Material Icons, serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}
.viewer-rectify-mask::before {
  // double arrow up
  content: '\e3c9';
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft {
  // over viewer.js (2015)
  z-index: 2020;
}

div.visualizer-image-preview-popover {
  position: fixed;
  // under drop-down (1300)
  z-index: 1200;
  background: $app-box-bg;
  border: 2px solid $gs-primary;
  padding: 10px;
  height: min-content;
  div.close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: $app-box-bg;
    border: none;
  }
}

.radio-label {
  display: flex;
  gap: 5px;
}

.chart-button-wrap {
  position: sticky;
  top: 45px;
  z-index: 1;
  display: flex;
  height: 0;
  flex-direction: column;
}

.icon-button {
  border-radius: 50%;
  left: -15px;
  position: absolute;
  width: fit-content;
}

// make registered image names (in bulk uploader) look like links
.registered-overlap {
  color: $gs-primary;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: lighten($gs-primary, 20%);
  }
  &.active {
    color: lighten($gs-primary, 40%);
  }
}

.range-container {
  .range-slider-form-control {
    min-height: 3rem;
  }
  .range-slider-label {
    top: 0.9rem;
  }
  .range-ends-form-control {
    min-height: 4rem;
  }
  .range-ends-label {
    top: 1.1rem;
  }
  .range-ends-input {
    max-width: 100px;
  }
}

.sidebar-menu-item {
  text-decoration: none;
  color: inherit;
  &.accessible:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.secondary span {
    font-size: 0.9rem;
  }
  .menu-item-icon {
    min-width: 40px;
  }
}

.job-progress-container {
  position: relative;
  display: block;
  width: 100%;
}

.job-progress-status-container,
.job-progress-current-step-container {
  width: 50%;
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
}

.job-progress-status-container span,
.job-progress-current-step-container span {
  font-size: 0.9em;
}

.job-progress-status-container {
  justify-content: left;
}

.job-progress-current-step-container {
  justify-content: right;
}

.job-progess-list-item span,
.job-progess-list-item {
  font-size: 0.9em;
}

.job-progress-line {
  width: 100%;
  height: 0.5em !important;
  border-radius: 5px;
  display: block;
  margin-bottom: 0.25em;
}

.job-progress-current-step-label span,
.job-progress-status-label span {
  font-size: 0.9em;
}

.job-progress-icon {
  font-size: 1em !important;
  margin-right: 5px;
}

.gallery-missing-thumbnail {
  color: $darken-default-background;
}

@media (max-width: $break-point-tablet-portrait) {
  // 600 px
  .App-box {
    padding: $app-box-padding-mobile;
    height: inherit !important;
  }
}
@media (max-width: $break-point-tablet-landscape) {
  // 900 px
  .App-box {
    min-height: inherit;
    .bottom-button {
      position: inherit;
      bottom: auto;
      margin-top: 16px;
    }
  }
}
